define("home/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute('index'), this.toRoute('work'), this.use('toLeft'), this.reverse('toRight'));
  }
});