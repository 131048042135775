define("home/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "waXKO25V",
    "block": "{\"symbols\":[\"nav\",\"dd\",\"ddm\"],\"statements\":[[5,\"bs-nav\",[],[[\"@type\",\"@justified\",\"@stacked\",\"@fill\"],[\"pills\",false,false,false]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"link-to\"]],[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"link-to\"]],[],[[\"@route\"],[\"work\"]],{\"statements\":[[0,\"Work\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"dropdown\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"toggle\"]],[],[[],[]],{\"statements\":[[0,\"\\n      Dropdown\\n      \"],[7,\"span\",true],[10,\"class\",\"caret\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,2,[\"menu\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,3,[\"link-to\"]],[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[6,[23,3,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,3,[\"link-to\"]],[],[[\"@route\"],[\"work\"]],{\"statements\":[[0,\"Nav\"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[7,\"main\",true],[8],[0,\"\\n\"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "home/templates/application.hbs"
    }
  });

  _exports.default = _default;
});